<template>
  <div class="mobile-home">
    <!-- 轮播 -->
    <div class="swiper-box">
      <swiper :options="swiperOption" >
        <swiper-slide>
          <img src="../assets/mobile_images/mo_banner4.png"/>
          <div class="swiper-btn" @click="toPath('/company/cs')">进一步了解</div>
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/mobile_images/mo_banner1.png"/>
          <div class="swiper-btn" @click="toPath('/hospital/information')">进一步了解</div>
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/mobile_images/mo_banner2.png"/>
          <div class="swiper-btn" @click="toPath('/hospital/logistics')">进一步了解</div>
        </swiper-slide>
        <swiper-slide><img @click="toPath('/hospital/intelligence')" src="../assets/mobile_images/mo_banner3.png"/></swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <!-- 广告招募 -->
    <div class="advert" @click="goAdvert">
      <img src="../assets/mobile_images/mo_advert.png" alt="" />
    </div>
    <!-- 帮医院提质 -->
    <mobileTitle titleZh="帮医院提质" titleEn="Help hospitals improve quality"></mobileTitle>
    <div class="mobile-yi-box">
      <div class="mobile-yi-list" @click="toPath('/hospital/logistics')">医院物流建设整体解决方案</div>
      <div class="mobile-yi-list" @click="toPath('/hospital/information')">信息化产品</div>
      <div class="mobile-yi-list" @click="toPath('/hospital/intelligence')">智能化设备</div>
      <div class="mobile-yi-list" @click="toPath('/hospital/operate')">SPD运营</div>
    </div>
    <!-- 助药企增效 -->
    <mobileTitle titleZh="助药企增效" titleEn="Help pharmaceutical enterprises increase efficiency"></mobileTitle>
    <div class="mobile-yi-box mobile-yao-box">
      <div class="mobile-yi-list" @click="toPath('/medicine/case')">FBT一站式解决方案</div>
      <div class="mobile-yi-list" @click="toPath('/medicine/platform')">供应链平台</div>
      <div class="mobile-yi-list" @click="toPath('/medicine/data')">数据产品</div>
      <div class="mobile-yi-list" @click="toPath('/medicine/logistics')">物流中心建设整体解决方案</div>
    </div>
    <!-- 构健康生态 -->
    <mobileTitle titleZh="构健康生态" titleEn="Build a healthy ecology"></mobileTitle>
    <mobileTab :tabs="tabs" @tabChange="onTabChange" ref="tabs"></mobileTab>
    <img v-show="currentIndex == 0" class="w90f" src="../assets/mobile_images/mo_home_js.png" alt="">
    <img v-show="currentIndex == 1" class="w90f" src="../assets/mobile_images/mo_home_hb.png" alt="">
    <img v-show="currentIndex == 2" class="w90f" src="../assets/mobile_images/mo_home_lj.png" alt="">
    <mobileTitle titleZh="合作伙伴" titleEn="排名不分先后" fontSizeEn=".28rem" marginBottomZh=".6rem"></mobileTitle>
    <img class="w100f" src="../assets/mobile_images/mo_home_hzhb.png" alt="">
  </div>
</template>
<script>
import mobileTab from '@/components/tab/mobile_tab.vue'; //选项卡组件
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
export default {
  components: {
    swiper,
    swiperSlide,
    mobileTitle,
    mobileTab
  },
  data() {
    return {
      currentIndex: 0,
      tabs: [{ title: '技术' }, { title: '伙伴' }, { title: '连接' }],
      swiperOption: {
        loop: true, // 设置图片循环
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        }, 
        pagination: {
          el: '.swiper-pagination', //分页器的类名
          clickable: true, //设置分页小圆点可手动点击
        },
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
        observer:true,
        observeParents:true,
        initialSlide : 0, 
      },
    };
  },
  methods: {
    toPath(val, index) {
      if (val == 'fbt') {
        window.open('http://csbrkjcom.huhuhu.net/index.html');
      } else {
        setTimeout(() => {
          this.$router.push(val);
        }, 500);
      }
    },
    onTabChange(item, index) {
      this.currentIndex = index;
      // this.$refs.tabs.currentIndex = index
      console.log(index);
    },
    goAdvert(){
      this.$router.push({
          path: "/news/detail",
          query:{
          id:3
        }
      });
    }
  },
};
</script>
<style lang="less" scoped>
.mobile-home{
  //轮播
  .swiper-box {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }
    .swiper-btn{
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: .72rem;
      line-height: .72rem;
      font-size: .28rem;
      text-align: center;
      color: #fff;
      background: #4FA1A4;
      border-radius: .04rem;
    }

  }
  // 广告招募
  .advert{
    width: 100%;
    img {
      width: 100%;
    }
  }
  .mobile-yi-box{
    height: 6.42rem;
    color: #fff;
    font-size: .32rem;
    background: url('../assets/mobile_images/mo_home_yi.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .mobile-yi-list{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25%;
    }
  }
  .mobile-yao-box{
    background: url('../assets/mobile_images/mo_home_yao.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

</style>
