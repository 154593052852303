<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <!-- <div class="home-header">
        <img class="w100f" src="../assets/images/home_bg.jpg" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title">技术引导变革 数据驱动服务</p>
            <p class="font-en">Technology leads transform, Data drives serviceValue</p>
            <p class="font-en">Transparent Word</p>
            <div class="next-btn mt20" @click="toPath('/hospital/information')">进一步了解</div>
          </div>
          <div class="bg-text-right"></div>
        </div>
      </div> -->
      <!-- 轮播 -->
      <div class="swiper-box">
        <swiper :options="swiperOption" >
          <swiper-slide>
            <img src="../assets/images/home_lunbo2.jpg"/>
            <div class="swiper-btn" @click="toPath('/company/cs')">进一步了解</div>
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/home_lunbo1.jpg"/>
            <div class="swiper-btn" @click="toPath('/hospital/information')">进一步了解</div>
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/home_lunbo3.jpg"/>
            <div class="swiper-btn" @click="toPath('/hospital/logistics')">进一步了解</div>
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/images/home_lunbo4.jpg"/>
            <div class="swiper-btn" @click="toPath('/hospital/intelligence')">进一步了解</div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <!-- 头部结束 -->
      <!-- 广告招募 -->
      <div class="advert" @click="goAdvert">
        <img src="../assets/images/advert.jpg" alt="" />
      </div>
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <!-- 帮医院提质 -->
        <Title titleZh="帮医院提质" titleEn="Help hospitals improve quality"></Title>
        <div class="yi-box yi-box-wrap">
          <img src="../assets/images/yi_bg.png" alt="" />
          <div class="yi-box-content">
            <div class="yi-box-left">
              <div class="yi-left-top"></div>
              <div class="yi-left-bottom">
                <div class="yi-left-title">医院物流建设整体解决方案</div>
                <div class="yi-left-btn" @click="toPath('/hospital/logistics')">进一步了解</div>
              </div>
            </div>
            <div class="yi-box-right">
              <div class="yi-right-list yi-right-list-bg">
                <div class="yi-right-title">信息化产品</div>
                <div class="yi-right-txt yi-right-btn" @click="toPath('/hospital/information')">进一步了解</div>
              </div>
              <div class="yi-right-list">
                <div class="yi-right-title">智能化设备</div>
                <div class="yi-right-txt" @click="toPath('/hospital/intelligence')">进一步了解</div>
              </div>
              <div class="yi-right-list">
                <div class="yi-right-title">SPD运营</div>
                <div class="yi-right-txt" @click="toPath('/hospital/operate')">进一步了解</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 助药企增效 -->
        <Title titleZh="助药企增效" titleEn="Help pharmaceutical enterprises increase efficiency"></Title>
        <div class="yi-box yao-box-wrap">
          <img src="../assets/images/yao_bg.png" alt="" />
          <div class="yao-box-content">
            <div class="yao-box-left">
              <div class="yao-left-top"></div>
              <div class="yao-left-bottom">
                <div class="yao-left-title">FBT一站式解决方案</div>
                <div class="yao-left-btn" @click="toPath('/medicine/case')">进一步了解</div>
              </div>
            </div>
            <div class="yao-box-right">
              <div class="yao-right-list">
                <div class="yao-right-title">供应链平台</div>
                <div class="yao-right-txt" @click="toPath('/medicine/platform')">进一步了解</div>
              </div>
              <div class="yao-right-list">
                <div class="yao-right-title">数据产品</div>
                <div class="yao-right-txt" @click="toPath('/medicine/data')">进一步了解</div>
              </div>
              <div class="yao-right-list">
                <div class="yao-right-title">物流中心建设整体解决方案</div>
                <div class="yao-right-txt" @click="toPath('/medicine/logistics')">进一步了解</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 构健康生态 -->
        <Title titleZh="构健康生态" titleEn="Build a healthy ecology"></Title>
        <div class="h40"></div>
        <Tab :tabs="tabs" @tabChange="onTabChange" ref="tabs"></Tab>
        <div class="h39"></div>
        <!-- 连接 -->
        <img class="w100f" v-show="currentIndex == 0" src="../assets/images/home_tab1.png" alt="" />
        <img class="w50f" v-show="currentIndex == 1" src="../assets/images/home_tab2.png" alt="" />
        <img class="w50f" v-show="currentIndex == 2" src="../assets/images/home_concat.png" alt="" />
        <!-- 合作伙伴 -->
        <Title titleZh="合作伙伴" titleEn="排名不分先后"> </Title>
        <div class="yi-box yi-box-fri">
          <img src="../assets/images/home_fri.png" alt="" />
        </div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>
<script>
import Tab from '@/components/tab/tab.vue'; //选项卡组件
import Title from '@/components/title/title.vue'; //标题组件
import mobilePage from '@/views/moblie_home.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
export default {
  name: 'home',
  components: {
    Tab,
    Title,
    mobilePage,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      currentIndex: 0,
      tabs: [{ title: '技术' }, { title: '伙伴' }, { title: '连接' }],
      swiperOption: {
        loop: true, // 设置图片循环
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        }, 
        pagination: {
          el: '.swiper-pagination', //分页器的类名
          clickable: true, //设置分页小圆点可手动点击
        },
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
        observer:true,
        observeParents:true,
        initialSlide : 0, 
      },
    };
  },
  methods: {
    toPath(val, index) {
      if (val == 'fbt') {
        window.open('http://csbrkjcom.huhuhu.net/index.html');
      } else {
        
        setTimeout(() => {
          this.$router.push(val);
        }, 500);
        localStorage.setItem('currentIndex', index);
      }
    },
    onTabChange(item, index) {
      this.currentIndex = index;
      // this.$refs.tabs.currentIndex = index
      console.log(index);
    },
    goAdvert(){
      window.open('https://csbr.cn/news/detail?id=3')
    }
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    min-height: 450px;
    .bg-text {
      width: 1200px;
      height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 40px;
          margin-bottom: 39px;
        }
        .font-en {
          font-size: 28px;
          margin-bottom: 19px;
          font-weight: 300;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
          margin-top: 40px;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //轮播
  .swiper-box {
    width: 100%;
    min-width: 1200px;
    position: relative;
    img {
      width: 100%;
    }
    .swiper-btn{
      position: absolute;
      left: 13%;
      bottom: 30%;
      // transform: translateX(-50%);
      width: 140px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #fff;
      background: #4fa1a4;
      opacity: 1;
      border-radius: 24px;
      text-align: center;
      cursor: pointer;
    }

  }
  // 广告招募
  .advert{
    width: 100%;
    min-width: 1200px;
    position: relative;
    img {
      width: 100%;
    }
    margin-top: -9px;
    
  }
  //中间
  .main-contain {
    width: 1200px;
    margin: 0 auto;

    .contain-title-box {
      text-align: center;
      color: #3d3d3d;
      margin-top: 120px;
      margin-bottom: 39px;
      .contain-title {
        font-size: 32px;
      }
      .contain-title-en {
        font-size: 20px;
      }
    }
    //医 样式
    .yi-box-wrap {
      position: relative;
      .yi-box-content {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        color: #fff;
        .yi-box-left {
          width: 61%;
          .yi-left-top {
            height: 66.6%;
          }
          .yi-left-bottom {
            height: 33.3%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: left;
            padding-left: 60px;
            .yi-left-title {
              font-size: 22px;
              margin-bottom: 14px;
            }
            .yi-left-btn {
              width: 126px;
              height: 35px;
              font-size: 16px;
              line-height: 35px;
              background: #4fa1a4;
              text-align: center;
              border-radius: 20px;
              cursor: pointer;
            }
          }
        }
        .yi-box-right {
          flex: 1;
          .yi-right-list {
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 33%;
            text-align: left;
            padding-left: 39px;
            .yi-right-title {
              font-size: 22px;
              margin-bottom: 16px;
            }
            .yi-right-txt {
              font-size: 16px;
              cursor: pointer;
            }
            &:hover {
              transition: 0.8s;
              height: 33%;
              background: rgba(#4fa1a4, 0.76);
              .yi-right-txt {
                width: 126px;
                height: 35px;
                font-size: 16px;
                line-height: 35px;
                background: #4fa1a4;
                text-align: center;
                border-radius: 20px;
                opacity: 1;
              }
            }
          }
        }
      }
    }
    //药 样式
    .yao-box-wrap {
      position: relative;
      .yao-box-content {
        position: absolute;
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        color: #fff;
        .yao-box-left {
          width: 61.2%;
          .yao-left-top {
            height: 66.6%;
          }
          .yao-left-bottom {
            height: 33%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: right;
            padding-right: 60px;
            .yao-left-title {
              font-size: 22px;
              margin-bottom: 14px;
            }
            .yao-left-btn {
              width: 126px;
              height: 35px;
              font-size: 16px;
              line-height: 35px;
              background: #4fa1a4;
              text-align: center;
              border-radius: 20px;
              cursor: pointer;
              margin-left: auto;
            }
          }
        }
        .yao-box-right {
          flex: 1;
          .yao-right-list {
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 33%;
            text-align: left;
            padding-left: 39px;
            cursor: pointer;
            .yao-right-title {
              font-size: 22px;
              margin-bottom: 16px;
            }
            .yao-right-txt {
              font-size: 16px;
            }
            &:hover {
              transition: 0.8s;
              height: 33%;
              background: rgba(#4fa1a4, 0.76);
              .yao-right-txt {
                width: 126px;
                height: 35px;
                font-size: 16px;
                line-height: 35px;
                background: #4fa1a4;
                text-align: center;
                border-radius: 20px;
                opacity: 1;
              }
            }
          }
          .yao-right-list-bg {
            background: rgba(#4fa1a4, 0.76);
          }
        }
      }
    }
    .yi-box {
      img {
        width: 100%;
      }
    }
    .yi-box-fri {
      margin-bottom: 152px;
    }
    .contain-tab {
      margin-bottom: 300px;
      height: 200px;
      border-bottom: 1px solid #e0e5ed;
    }
  }
}
</style>
